
////Translations.translate('definitions');
////Translations.translate('core');
// DEFINTIONS-FR.JSON
// {
//     "translations": [{
//         "selector": ".readMore",
//         "type": "text",
//         "translation": "En savoir plus >"
//     }]
// }

var Translations = Translations || (function() {

    var jsondata = {};

    var makeTranslations = function(storeKey, scope) {
        $.each(jsondata[storeKey].translations, function(i, val) {
            if (val.type === 'text') {
                if (scope) {
                    $(scope).find(val.selector).html(val.translation);
                } else {
                    $(val.selector).html(val.translation);
                }
            } else if (val.type === 'input') {
                if (scope) {
                   $(scope).find(val.selector).val(val.translation);
                } else {
                    $(val.selector).val(val.translation);
                }
            }
        });
    };

    var translate = function(key, scope) {
        // if key exists then translation content is either loaded or being loaded
        // just make the translations, if the content is being loaded then the
        // translate is carried out upon finishing the load...

        //
        //var lang = $("meta[name='language']").attr('content');
        //if (lang === undefined) {
        //    lang ='nl';
        //} else {
        //    lang = lang.substring(0,2);
        //}


        var lang = $("meta[name='language']").attr('content');
        var localStorageLang = localStorage.getItem("CCIGroepSTSLanguage");
        if (lang) {
            lang = lang.substring(0,2);
        } else if(localStorageLang){
            lang = localStorageLang.substring(0,2);
        } else {
            lang ='nl';
        }



        var storeKey = key + '-' + lang;
        if (jsondata.hasOwnProperty(storeKey)) {
            makeTranslations(storeKey, scope);
        } else {
            // prevent recursion
            jsondata[storeKey] = '{workInProgress: true}';
            var uri = '/json/1/' + storeKey + '.json';
            // get translations and translate
            $.get(uri, function(json) {
                jsondata[storeKey] = json;
                makeTranslations(storeKey, scope);
            });
        }
    };

    return {
        translate: translate
    };
}());
