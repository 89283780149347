$(document).foundation({
  equalizer : {
    // Specify if Equalizer should make elements equal height once they become stacked.
    equalize_on_stack: true
  }
});


$(document).ready(function(){
	var lang = localStorage.getItem('CCIGroepSTSLanguage');
	var getCurrentLocation = window.location.href;

    if(getCurrentLocation ==='https://www.marketing.be/home' && lang ==='fr-BE'){
        window.location = 'https://www.marketing.be/fr/home'
    }

    if ($('.changepassword_successmessage').length > 0){
        var lang = localStorage.getItem('CCIGroepSTSLanguage');
        if (lang === 'fr-BE'){
            $('.changepassword_successmessage').text('Votre mot de passe a été modifié');
        }
    }

	if ('.workgroupSections') {
		if (lang != 'nl-BE') {
			$('h3').each(function(){
				var title = $.trim($(this).html());
				console.log(title);
				var translation = '';
				switch(title) {
					case 'Leden':
						translation = 'Membres';
						break;
					case 'Documenten':
						translation = 'Documents';
						break;
					case 'Geplande vergaderingen':
						translation = 'Réunions';
						break;
					case 'Voorbije vergaderingen':
						translation = 'Réunions précédentes';
						break;
					default:
						translation = title;
						break;
				}
				$(this).html(translation);
			});
		}
	}
	if ($('#input_container_shop_orderform_betaalwijze')) {
		$('#input_container_shop_orderform_betaalwijze option').each(function(){
			if ($(this).val() == 'FACTUUR~3') {
				$(this).text('Overschrijving');
			}
			if ($(this).val() == 'OGONE~3') {
				$(this).text('Online betalen');
			}
		});
	}

    // Wat is de page ID ? Indien 55, dan door
    function getPageID(name) {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(document.getElementsByTagName("form")[0].getAttribute("action"));
        if (results == null)
            return "";
        else
            return results[1];
    }
    var pageID = getPageID('id');
    if (pageID == 55) {
        // Versnel slider
        $('.slider.variant2 .slideWrapper').slick('slickSetOption', 'autoplaySpeed', 3000, true);
    }

    // Show search box when pages var 4 and dropdown setting is ShowSearchBox

    if ($('.pages.variant4.ShowSearchBox').length) {
        document.getElementById('Block_1_Pages').addEventListener( 'DOMNodeInserted', function ( event ) {
            // ShowSearchBox found, and data has been added to the page. Now add the searchbox
			if (!$('#searchBoxPages4').length) {
                $('.pages.variant4.ShowSearchBox .grid').before('<input type="search" id="searchBoxPages4" placeholder="Zoek uw lid.." onkeyup="searchThroughPageResults()" />');
			}
        }, false );
    }
});

$(document).ajaxStop(function() {
    var lang = localStorage.getItem('CCIGroepSTSLanguage');
    if (lang === 'fr-BE') {
        var winkelmand = $('#CmsShowShopOrderFormRenderControl1_OrderFormWizard_SideBarContainer_SideBarList_SideBarButton_0').text();
		var betalingsmethode = $('#CmsShowShopOrderFormRenderControl1_OrderFormWizard_SideBarContainer_SideBarList_SideBarButton_1').text();
		var bevestiging = $('#CmsShowShopOrderFormRenderControl1_OrderFormWizard_SideBarContainer_SideBarList_SideBarButton_2').text();

        // Indien FR language verander benamingen:
        if (winkelmand === 'Winkelmandje') {
            $('#CmsShowShopOrderFormRenderControl1_OrderFormWizard_SideBarContainer_SideBarList_SideBarButton_0').text('Panier');
        }
        if (betalingsmethode === 'Betalingsmethode') {
            $('#CmsShowShopOrderFormRenderControl1_OrderFormWizard_SideBarContainer_SideBarList_SideBarButton_1').text('mode de paiement');
        }
        if (bevestiging === 'Bevestiging van uw bestelling') {
            $('#CmsShowShopOrderFormRenderControl1_OrderFormWizard_SideBarContainer_SideBarList_SideBarButton_2').text('confirmation de votre commande');
        }
        if($(".modifypersonalattributeinfo_button_label input").length === 1){
            $(".modifypersonalattributeinfo_button_label input").attr("value" , "Envoyer");
        };

        if($("#Before_Content_Block_Uitloggen_ctl00").length === 1){
            $("#Before_Content_Block_Uitloggen_ctl00").text("Se déconnecter");
        };
        if($(".SN_PriveData_Label.SN_PriveData_VrijVeld1_Label").length === 1){
            $(".SN_PriveData_Label.SN_PriveData_VrijVeld1_Label").text("Code de langue");
        };
        /*Change communcation preference to english*/
        $(".change.communication #containerPOST label").text("Adresse postale")
        $(".change.communication #containerEMAIL label").text("Email")
        $(".change.communication #containerTELEFOON label").text("Téléphone")
        $(".change.communication #containerMOBIEL label").text("Mobile")
        $(".change.communication #containerDIGITALENIEUWSBRIEF label").text("Newsletter ")
        $("div[id^='changeCommunicationPreferenceForm'] input[type=submit]").attr("value" , "Envoyer")

    }
    else{
        /*Change communcation preference to dutch*/
        $(".change.communication #containerPOST label").text("Post")
        $(".change.communication #containerEMAIL label").text("E-mail")
        $(".change.communication #containerTELEFOON label").text("Telefoon")
        $(".change.communication #containerMOBIEL label").text("Mobiel")
        $(".change.communication #containerDIGITALENIEUWSBRIEF label").text("Digitale nieuwsbrief")
        $("div[id^='changeCommunicationPreferenceForm'] input[type=submit]").attr("value" , "Opslaan")
    }
});


function searchThroughPageResults() {
    // variabelen
    var inputField, filterTekst, ResultRow, a, i;
    inputField = document.getElementById('searchBoxPages4');
    filterTekst = inputField.value.toUpperCase();
    ResultRow = document.getElementById('Block_1_Pages').getElementsByTagName("li");

    // Loop items door en verberg indien zoekresultaat niet matched
    for (i = 0; i < ResultRow.length; i++) {
        a = ResultRow[i].getElementsByTagName("a")[0];
        if (a.innerHTML.toUpperCase().indexOf(filterTekst) > -1) {
            ResultRow[i].style.display = "";
        } else {
            ResultRow[i].style.display = "none";
        }
    }

    // Is de letter nog gevuld? Dan heel blokje weg, en masonry opnieuw uitvoeren
    $('.grid-item').each(function() {
        var aantalElementenZichtbaar = 0;

        $(this).find('li').each(function() { // this = grid-item

            // Default: if li is visible, count visible Elements
            if ($(this).is(":visible")) { // this = li
                aantalElementenZichtbaar += 1;
            } else if ($(this).attr('style') === '') { // Fallback: if visible, but parent invisible
                aantalElementenZichtbaar += 1;
            }
        });
        if (aantalElementenZichtbaar === 0) {
            $(this).hide(); // this = grid-item
        } else {
            $(this).show();
        }

        // en masonry nog uitvoeren
        $('.grid').masonry({
            itemSelector: '.grid-item',
            columnWidth: 0,
            percentPosition: true,
            isAnimated: !Modernizr.csstransitions, // Check if it can be animated
            animationOptions: {
                duration: 750,
                easing: 'linear',
                queue: false
            }
        });
    });
}
// <input type="text" id="zoekleden" onkeyup="myFunction()" placeholder="Zoek uw lid..">

